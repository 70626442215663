// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/alert-triangle.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html {\n  overflow-y: auto;\n}\n.fill-width {\n  width: 100%;\n}\n.text-no-wrap {\n  white-space: nowrap !important;\n}\n.exception {\n  height: 85%;\n}\n.exception-img {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  height: 240px;\n  width: 360px;\n}", ""]);
// Exports
module.exports = exports;
