var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "exception fill-width d-flex flex-column justify-center align-center",
    },
    [
      _c("div", {
        staticClass: "exception-img mb-6",
        style: { filter: _vm.appThemeDark ? "invert(100%)" : "none" },
      }),
      _c("h1", [_vm._v("interface")]),
      _c("h1", [_vm._v(_vm._s(_vm.config.title))]),
      _c("h2", [_vm._v(_vm._s(_vm.config.desc))]),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary mr-4",
              attrs: { depressed: "", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/home")
                },
              },
            },
            [_vm._v(" back to front page ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: { depressed: "", type: "primary" },
              on: { click: _vm.logout },
            },
            [_vm._v(" Log in again ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }